import { inject, Injectable } from '@angular/core';
import * as jose from 'jose';
import { AuthenticateGQL } from "../../graphql/generated";

export const LS_KEY_JWT = 'jwt';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private authenticateGQL = inject(AuthenticateGQL);

  login(username: string, password: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.authenticateGQL.mutate({
        data: {
          username,
          password
        },
      }).subscribe({
        next: response => {
          if (response.data?.authenticate.success) {
            localStorage.setItem(LS_KEY_JWT, response.data.authenticate.jwt!);
            resolve(true);
          } else {
            reject(false);
          }
        },
        error: (error: any) => {
          reject(false);
          throw error;
        }
      });
    });
  }

  logout(): Promise<void> {
    return new Promise<void>((resolve) => {
      localStorage.removeItem(LS_KEY_JWT);
      resolve();
    });
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        let token: string = localStorage.getItem(LS_KEY_JWT) || '';
        let expireTime = jose.decodeJwt(token).exp;

        if(expireTime == undefined)
          expireTime = 0;

        // @ts-ignore
        if(expireTime == 0 || jose.decodeJwt(token).exp < Math.floor(Date.now() / 1000)) throw 'Token Expired!';

        resolve(true);
      } catch(e) {
        resolve(false);
      }
    });
  }
}
